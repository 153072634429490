import "./header.css";
import "./StepUpAuth.css";

const Confirmation = () => {

  return (<>
    <div className="fade show" data-backdrop="static" data-keyboard="false" tabIndex={-1} style={{display: "block"}} aria-labelledby="staticBackdropLabel" aria-hidden="true" role='dialog' >
      <div className="modal-dialog modal-lg">
        <div className="modal-content-m">
          <form>
            <div className="modal-body-m">
              <div className="row" style={{justifyContent:"center"}}>
                <div style={{textAlign:"center",paddingBottom:"1rem"}} >We have sent you a sign-up confirmation link. Please follow the steps in it.</div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>)
}

export default Confirmation;