
const Loader = () =>{

    return (<>
    <div id="overlay">
      <div className="spinner-border"></div><br/>
      <div className="font-weight-bold">Loading...</div>
    </div>
    </>)
}
export default Loader;