import React, { useState } from 'react';
import './modal.css'
interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  onOtpConfirm: () => void;
  isOtpVerified: boolean;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose, onConfirm, isOtpVerified, onOtpConfirm }) => {
  const handleConfirm = () => {
    onConfirm();
    onClose();
  };
  const handleOtpConfirm = () =>{
    onOtpConfirm();
    onClose()
  }

  return (
    <>
      {isOpen && (
        <div className="modal" style={{ display: 'block'}}>
          <div className="passwordless-fido-recommendation "  style={{flexDirection: "column",right:"18vw",left:"18vw",animation: "r .3s"}}>
            <div className="passwordless-fido-recommendation-text" style={{maxWidth: "885px"}} >
              {isOtpVerified ? ('A User has already been registered with this email and is pending activation. Do you want to resend the activation link?') : ('User has already registered, Do you want to send the otp again?')}
              <div className="btn-centered" style={{display: "flex",flexWrap: "wrap",justifyContent: "center",gap: "23px",alignContent: "stretch",alignItems: "stretch"}}>
                {isOtpVerified ? (<button className='passwordless-button passwordless-button-add-face-touch-unlock' onClick={handleConfirm}>Resend Activation Mail</button>) : (<button className='passwordless-button passwordless-button-add-face-touch-unlock' onClick={handleOtpConfirm}>Yes</button>)}
                {!isOtpVerified && (<button className="passwordless-link" onClick={onClose}>No</button>)}
              </div>
            </div>
          </div>
       </div>
      )}
    </>
  );
};
export default Modal;
