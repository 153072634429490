import { createContext, useContext, useState } from 'react';
import Header from '../../src/Header';
import './personalizeavatar.css';
import { useNavigate } from 'react-router-dom';


 function PersonalizeAvatar(){
  const avatarList = [
    'avatar1.jpg',
    'avatar2.png',
    'avatar3.png',
    'avatar4.png',
    'avatar5.jpg',
    'avatar6.jpg',
    'avatar7.jpg',
    'avatar8.jpg',
    'avatar9.png',
    'avatar10.jpg',
    'avatar11.jpg',
    'avatar12.png',
    'avatar13.jpg',
    'avatar14.jpg',
    'avatar15.jpg',
  ];

  const [selectedAvatar, setSelectedAvatar] = useState<string | null>(null);
  const navigate=useNavigate();
  const selectAvatar = (avatarId: string) => {
    setSelectedAvatar(avatarId);
    console.log(avatarId)
    
  };
  
  const clearSelectedAvatar = () => {
    console.log("hfhgf")
    setSelectedAvatar(null);
  };
    return (
        <>
          <Header />
        <section className="mt-5">
          <div className="container">
            <h3 className="text-400 text-black text-center text-mb mb-4 custom">PERSONALIZE MY ACCOUNT</h3>
            <div className="row">
              <div className="col-sm-9 avatar">
                <div className="row">
                <div className="col-sm">
                    <h6 className="text-600 text-black text-left text-mb mb-5">Select An Avatar</h6>
                </div>
                </div> 
                  <div className="row">
                    <div className="col-sm" style={{display:"flex", flexWrap: "wrap", justifyContent: "space-between"}}>
                    {avatarList.map((avatar, index) => (
                    <img
                    key={index}
                    src={`./public/avatars/${avatar}`}
                    alt={`Avatar ${index + 1}`}
                    className="avatar-img-circle img-circle img-thumb-options p-2"
                    id={`avatar-${avatar}`}
                    onClick={() => selectAvatar(`${avatar}`)}
                    style={{ height: "140px", width: "140px" ,border: selectedAvatar === `${avatar}` ? "3px solid green" : ""}} // Adjust size as needed
                    />
                    ))}
                    </div>
                    </div>
                <div className="row mt-3">
                  <div className="col-sm">or upload your own</div>
                  </div>
                <div className="row mt-2">
                  <div className="col-sm">
                      <input className="border-top-0 border-right-0 border-left-0 border-secondary rounded-0 mb-4" accept="image/*" type="file" name="avatar" id="avatar" />
                  </div>
                </div>
                <div className="row pt-3 pr-5">
                    <div className="col-sm mb-3">
                        <button className="btn btn-block btn-outline btn-lg text-primary border border-primary rounded-0" type="submit">NEXT</button>
                    </div>
                    <div className="col-sm mb-3">
                        <button className="btn btn-block btn-outline btn-lg text-primary border border-primary rounded-0" type="submit">UPDATE AVATAR</button>
                    </div>
                </div>
                
                <div className="row">
                <div className="col text-center text-sm pt-3 pb-3">By logging in you agree to our <a href="https://marketrithm.com/terms">Terms &amp; Conditions</a> and <a href="https://marketrithm.com/privacy-compliance">Privacy Policy</a></div>
                </div>
              </div>
            </div>
          </div>
        </section>
        </>
    );
    
};

export default PersonalizeAvatar;