import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePasswordless } from "./hooks";
import Spinner from "./spinner";
const ForgotPasswordEmail = (props: { brand: {
    customerLogoUrl: string | undefined; backgroundImageUrl: string | undefined; 
  }; }) => {
    const {
      requestSignInLink,
      signInStatus,
      signingInStatus
    } = usePasswordless();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isLoading,setIsLoading] = useState(false);
    const [error,setError] = useState(false);
    
    const mail_regex = /\.+/;

    function submitHandler(){
      if(email == '' || !/@\S+/.test(email)){
        setError(false)  
      }
      else if(!mail_regex.test(email)){
        setError(true)
        setIsLoading(false)
      }
      else{
        setError(false)
      }
    }
    function generateMagicLinkForForgotPassword(){
      setIsLoading(true)
      requestSignInLink({
        username: email
      }).signInLinkRequested.then(response => {
        navigate('/')
        setIsLoading(false)
      }).catch(error => {
        console.error("Failed to Generate Forgot Password link:", error);
        navigate('/')
        setIsLoading(false)
      });
    }

    return (
      <div className="conatiner main-container">
        <div className="row" style={{height: 'inherit',fontFamily: "arboria, sans-serif",fontSize: "18px"}}>
          <div className="col-md-7" style={{paddingRight: 0}}>
            <div className="passwordless-main-container">
              <img
                src={props.brand.backgroundImageUrl}
                className="passwordless-main-background-image"
              />
            </div>
          </div>
          <div className="col-md-5" style={{display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent:'space-evenly'}}>
            <div className="container-login100">
              <div className="wrap-login100">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setIsLoading(true)
                    if(!error){
                      generateMagicLinkForForgotPassword();
                    }else{
                    setIsLoading(false)
                    }
                }}
                  className="login100-form ">
                    <img
                      src={props.brand.customerLogoUrl}
                      className="d-block ml-3 mr-3 mb-5"
                    />
                    <div className='wrap-input100'>
                      <input
                        className="input100 border-bottom border-top-0 border-right-0 border-left-0 rounded-1"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        type="email"
                        required
                      />
                      <span className='focus-input100'></span>
                    </div>
                    {error &&(<span className='small bold' id='form-error' style={{color:"red"}}>email is not valid!</span>)}
                    <div className="container-login100-form-btn">
                      <button className="login100-form-btn"
                      onClick={submitHandler}
                      >
                        SUBMIT
                      </button>
                    </div>
                    <div className='text-center p-t-46 p-b-20'>
                      <span><br/></span>
                      <span className='txt2'>
                        <a href="/">Go Back</a>
                      </span>
                    </div>
                    <div className="passwordless-flex">
                    {signingInStatus === "SIGNIN_LINK_EXPIRED" && (
                      <div className="passwordless-flex passwordless-flex-align-start">
                        <svg
                          width="24px"
                          height="24px"
                          viewBox="0 0 24 24"
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          className="rotate-45"
                        >
                          <path d="M18,11.1 L12.9,11.1 L12.9,6 L11.1,6 L11.1,11.1 L6,11.1 L6,12.9 L11.1,12.9 L11.1,17.9988281 L12.9,17.9988281 L12.9,12.9 L18,12.9 L18,11.1 Z M12,24 C5.38359372,24 0,18.6164063 0,12 C0,5.38300776 5.38359372,0 12,0 C18.6164063,0 24,5.38300776 24,12 C24,18.6164063 18.6164063,24 12,24 Z M12,1.8 C6.37617192,1.8 1.8,6.37558596 1.8,12 C1.8,17.6238281 6.37617192,22.2 12,22.2 C17.6238281,22.2 22.2,17.6238281 22.2,12 C22.2,6.37558596 17.6238281,1.8 12,1.8 Z"></path>
                        </svg>
                        <div>
                          <div className="passwordless-text-left">
                            <strong>Authentication error.</strong>
                          </div>
                          <div>The sign-in link you tried to use is no longer valid</div>
                        </div>
                      </div>
                    )}
                    </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        {(isLoading || signInStatus=='SIGNING_IN')&&(<Spinner/>)}
      </div>
    );
  };

  export default ForgotPasswordEmail;