import { useAwaitableState, useLocalUserCache, usePasswordless } from "amazon-cognito-passwordless-auth/react";
import { useEffect, useState } from "react";
import { timeAgo } from "../util.js";
import Loader from "../../src/Loader";
const Authenticators = () => {
    const {
        fido2CreateCredential,
        fido2Credentials
      } = usePasswordless();
      const { updateFidoPreference } = useLocalUserCache();
      const [error, setError] = useState<Error>();
      const [addingAuthenticatorStatus, setAddingAuthenticatorStatus] = useState<
        "IDLE" | "STARTING" | "INPUT_NAME" | "COMPLETING"
      >("IDLE");
      const [confirmDeleteRowIndex, setConfirmDeleteRowIndex] =
        useState<number>(-1);
      const [friendlyName, setFriendlyName] = useState("");
      const [editFriendlyNameRowIndex, setEditFriendlyNameRowIndex] = useState<number>(-1);
      const [editedFriendlyName, setEditedFriendlyName] = useState("");
      const { awaitable: awaitableFriendlyName, resolve: resolveFriendlyName } = useAwaitableState(friendlyName);
      const mobileDeviceName = determineMobileDeviceName();
      const [time, setTime] = useState(new Date());
      function reset() {
        setError(undefined);
        setConfirmDeleteRowIndex(-1);
        setEditFriendlyNameRowIndex(-1);
        setAddingAuthenticatorStatus("IDLE");
        setFriendlyName("");
        setEditedFriendlyName("");
      }
      useEffect(() => {
        const intervalId = setInterval(() => setTime(new Date()), 1000);
        return () => clearInterval(intervalId);
      }, []);
      const status = {
        isAddingAuthenticator: addingAuthenticatorStatus !== "IDLE",
        isDeletingAuthenticator: confirmDeleteRowIndex !== -1,
        isEditingAuthenticator: editFriendlyNameRowIndex !== -1,
      };

    return <>
    <section className="pt-5 container-fluid flex-grow-1" >
      <div className="container pt-4 ">
      {fido2Credentials?.length === 0 && (
        <><div className="passwordless-no-devices-yet py-4">
              <span>You don&apos;t have any authenticators yet.</span>
              <span>
                Press the button{" "}
                <strong>&quot;Register new authenticator&quot;</strong> to get
                started.
              </span>
            </div>
            <>
            {(addingAuthenticatorStatus === "INPUT_NAME" ||
            addingAuthenticatorStatus === "COMPLETING") && (
            <form
              className="passwordless-flex"
              onSubmit={(e) => {
                e.preventDefault();
                resolveFriendlyName();
                setAddingAuthenticatorStatus("COMPLETING");
                return false;
              }}
            >
              <div className="passwordless-fido-recommendation-text">
                Provide a name for this authenticator, so you can recognize it
                easily later
              </div>
              <input
                className="passwordless-friendly-name-input"
                autoFocus
                placeholder="authenticator name"
                value={friendlyName}
                onChange={(e) => setFriendlyName(e.target.value)}
              />
              <button
                className="passwordless-button passwordless-button-finish"
                type="submit"
                disabled={
                  !friendlyName || addingAuthenticatorStatus === "COMPLETING"
                }
              >
                Finish
              </button>
            </form>
            )}
            </>
          </>
        )}
        {!!fido2Credentials?.length && (
        <div className="auth-table" style={{paddingTop: "1.5rem"}}>
          <table className="table text-center table-hover table table-bordered table-striped table-sm mx-auto">
            <thead className="thead-dark">
              <tr>
                <th>Authenticators</th>
                <th>Last sign-in</th>
                <th>Created at</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
            {fido2Credentials.map((credential, index) => editFriendlyNameRowIndex === index ? (
              <tr key={credential.credentialId}>
              <td >
                <form
                  className="passwordless-edit-friendly-name"
                  onSubmit={(e) => {
                    e.preventDefault();
                    setError(undefined);
                    credential
                      .update({
                        friendlyName: editedFriendlyName,
                      })
                      .then(reset)
                      .catch(setError);
                    return false;
                  }}
                >
                  <input
                    className="passwordless-friendly-name-input"
                    autoFocus
                    value={editedFriendlyName}
                    onChange={(e) =>
                      setEditedFriendlyName(e.currentTarget.value)
                    }
                  />
                  <button
                    className="passwordless-button passwordless-button-save"
                    type="submit"
                    disabled={
                      credential.busy ||
                      !editedFriendlyName ||
                      editedFriendlyName === credential.friendlyName
                    }
                  >
                    Save
                  </button>
                  <button
                    className="passwordless-button passwordless-button-cancel"
                    onClick={() => setEditFriendlyNameRowIndex(-1)}
                    disabled={credential.busy}
                  >
                    Cancel
                  </button>
                </form>
              </td>
            </tr>
          ) : confirmDeleteRowIndex === index ? (
            <tr key={credential.credentialId}>
              <td colSpan={4}>
                <div className="passwordless-confirm-delete-device">
                  <span>
                    {" "}
                    Are you sure you want to delete your device named{" "}
                    <strong>
                      &quot;{credential.friendlyName}&quot;
                    </strong>
                    ?{" "}
                  </span>
                  <div>
                    <button
                      className="passwordless-button passwordless-button-save"
                      onClick={() => {
                        setError(undefined);
                        credential.delete().then(reset).catch(setError);
                      }}
                      disabled={credential.busy}
                    >
                      Yes
                    </button>
                    <button
                      className="passwordless-button passwordless-button-cancel"
                      onClick={() => {
                        setError(undefined);
                        setConfirmDeleteRowIndex(-1);
                      }}
                      disabled={credential.busy}
                    >
                      No
                    </button>
                  </div>
                </div>
              </td>
            </tr>
          ) : (
              <tr key={credential.credentialId}>
                <td><span><button
                onClick={() => {
                  setEditFriendlyNameRowIndex(index);
                  setEditedFriendlyName(credential.friendlyName);}}
                  disabled={
                    credential.busy ||
                    status.isAddingAuthenticator ||
                    status.isEditingAuthenticator ||
                    status.isDeletingAuthenticator
                  }
                className="passwordless-friendly-name btn-link border-none" >{credential.friendlyName}</button></span></td>
                <td>{timeAgo(time, credential.lastSignIn) || "Never"}</td>
                <td>{timeAgo(time, credential.createdAt) || "Unknown"}</td>
                <td><span>
                  <button className="btn"
                  onClick={() => {
                    setError(undefined);
                    setConfirmDeleteRowIndex(index);
                  }}
                  disabled={
                    credential.busy ||
                    status.isAddingAuthenticator ||
                    status.isEditingAuthenticator ||
                    status.isDeletingAuthenticator
                  }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 24 24" id="delete">
                      <path fill="none" d="M0 0h24v24H0V0z"></path>
                      <path d="M16 9v10H8V9h8m-1.5-6h-5l-1 1H5v2h14V4h-3.5l-1-1zM18 7H6v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7z"></path>
                    </svg>
                  </button>
                  </span><span>
                  <button className="btn"
                  onClick={() => {
                    setEditFriendlyNameRowIndex(index);
                    setEditedFriendlyName(credential.friendlyName);
                  }}
                  disabled={
                    credential.busy ||
                    status.isAddingAuthenticator ||
                    status.isEditingAuthenticator ||
                    status.isDeletingAuthenticator
                  }>
                    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="26" data-name="Layer 1" viewBox="0 0 24 24" id="edit">
                      <path d="M3.5,24h15A3.51,3.51,0,0,0,22,20.487V12.95a1,1,0,0,0-2,0v7.537A1.508,1.508,0,0,1,18.5,22H3.5A1.508,1.508,0,0,1,2,20.487V5.513A1.508,1.508,0,0,1,3.5,4H11a1,1,0,0,0,0-2H3.5A3.51,3.51,0,0,0,0,5.513V20.487A3.51,3.51,0,0,0,3.5,24Z"></path>
                      <path d="M9.455,10.544l-.789,3.614a1,1,0,0,0,.271.921,1.038,1.038,0,0,0,.92.269l3.606-.791a1,1,0,0,0,.494-.271l9.114-9.114a3,3,0,0,0,0-4.243,3.07,3.07,0,0,0-4.242,0l-9.1,9.123A1,1,0,0,0,9.455,10.544Zm10.788-8.2a1.022,1.022,0,0,1,1.414,0,1.009,1.009,0,0,1,0,1.413l-.707.707L19.536,3.05Zm-8.9,8.914,6.774-6.791,1.4,1.407-6.777,6.793-1.795.394Z"></path>
                    </svg>
                  </button>
                  </span></td>
              </tr>))}
            </tbody>
          </table>
          <div className="passwordless-authenticators-action-row text-right mt-3">
          {/* {(addingAuthenticatorStatus === "IDLE" ||
          addingAuthenticatorStatus === "STARTING") && (
          <button
            className="passwordless-button passwordless-button-add-authenticator"
            onClick={() => {
              setAddingAuthenticatorStatus("STARTING");
              setError(undefined);
              fido2CreateCredential({
                friendlyName: () => {
                  if (mobileDeviceName) return mobileDeviceName;
                  setAddingAuthenticatorStatus("INPUT_NAME");
                  return awaitableFriendlyName();
                },
              })
                .then(() => {
                  updateFidoPreference({ useFido: "YES" });
                  reset();
                })
                .catch(setError)
                .finally(() => setAddingAuthenticatorStatus("IDLE"));
            }}
            disabled={
              addingAuthenticatorStatus === "STARTING" ||
              status.isEditingAuthenticator ||
              status.isDeletingAuthenticator
            }
          >
            Register new authenticator
          </button>
          )} */}
          {(addingAuthenticatorStatus === "INPUT_NAME" ||
            addingAuthenticatorStatus === "COMPLETING") && (
            <form
              className="passwordless-flex"
              onSubmit={(e) => {
                e.preventDefault();
                resolveFriendlyName();
                setAddingAuthenticatorStatus("COMPLETING");
                return false;
              }}
            >
              <div className="passwordless-fido-recommendation-text">
                Provide a name for this authenticator, so you can recognize it
                easily later
              </div>
              <input
                className="passwordless-friendly-name-input"
                autoFocus
                placeholder="authenticator name"
                value={friendlyName}
                onChange={(e) => setFriendlyName(e.target.value)}
              />
              <button
                className="passwordless-button passwordless-button-finish"
                type="submit"
                disabled={
                  !friendlyName || addingAuthenticatorStatus === "COMPLETING"
                }
              >
                Finish
              </button>
            </form>
          )}
        </div>
      {error && (
        <div className="passwordless-authenticator-error">{error.message}</div>
      )}

        </div>
        )}
        <div className="passwordless-authenticators-action-row text-right">
        <button className="passwordless-buttonc passwordless-button-add-authenticator btn btn-pill btn-primary"
        onClick={() => {
          setAddingAuthenticatorStatus("STARTING");
          setError(undefined);
          fido2CreateCredential({
            friendlyName: () => {
              if (mobileDeviceName) return mobileDeviceName;
              setAddingAuthenticatorStatus("INPUT_NAME");
              return awaitableFriendlyName();
            },
          })
            .then(() => {
              updateFidoPreference({ useFido: "YES" });
              reset();
            })
            .catch(setError)
            .finally(() => setAddingAuthenticatorStatus("IDLE"));
        }}
        disabled={
          addingAuthenticatorStatus === "STARTING" ||
          status.isEditingAuthenticator ||
          status.isDeletingAuthenticator
        }
        style={{backgroundColor: "#354d9e",display: "inline-block",
        fontWeight:"400",
        textAlign: "center",
        verticalAlign: "middle",
        border: "1px solid transparent",
        padding: ".375rem .75rem",
        fontSize: "1rem",
        marginRight: "-1rem",
        lineHeight: "1.5",
        transition: "color .15s ease-in-out,backgroundColor .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out",color: "#fff",borderColor: "#000"}}>Register New Authenticator</button>
        </div>
        <div>
        <blockquote>
        <h4>More Security, Less Complexity</h4>
        Experience the seamless integration of security and simplicity with Market Rithm's MrSignIn Single Sign-On (SSO). This innovative solution employs phishing-resistant passkeys, offering a superior level of security compared to traditional passwords and SMS OTPs. Passkeys, easily synchronized across devices or specific to a platform, provide a user-friendly authentication method. With MrSignIn, access all your Market Rithm apps effortlessly using biometrics or a security key. Once authenticated, enjoy comprehensive access with minimal complexity.
        </blockquote>
        </div>
      </div>
    </section>
    {!fido2Credentials && (<Loader/>)}
    </>
  };

  export default Authenticators;

  function determineMobileDeviceName() {
    const mobileDevices = [
      "Android",
      "webOS",
      "iPhone",
      "iPad",
      "iPod",
      "BlackBerry",
      "Windows Phone",
    ] as const;
    return mobileDevices.find((dev) =>
      // eslint-disable-next-line security/detect-non-literal-regexp
      navigator.userAgent.match(new RegExp(dev, "i"))
    );
  }
