import { configure, } from "./config.js";
import { IdleState, BusyState, TokensFromSignIn } from "./model.js";

import {
  initiateAuth,
  respondToAuthChallenge,
  assertIsChallengeResponse,
  handleAuthResponse,
} from "./cognito-api.js";
import { defaultTokensCb } from "./common.js";
import { bufferFromBase64, bufferToBase64 } from "./util.js";

interface AuthenticatedResponse {
  AuthenticationResult: {
    AccessToken: string;
    IdToken: string;
    RefreshToken: string;
    ExpiresIn: number;
    TokenType: string;
  };
  ChallengeParameters: Record<string, string>;
}

export function assertIsMfaChallengeResponseFromPool(
  obj: any
){
  return !!obj && obj.ChallengeName && obj.ChallengeName == 'SMS_MFA';
}


export function assertIsMfaChallengeResponseFromLambdas(
  obj: any
){
  // !!obj && typeof obj === "object" && "AuthenticationResult" in obj;
  return !!obj && obj.ChallengeName && obj.ChallengeParameters && obj.ChallengeParameters.signInMethod && obj.ChallengeParameters.signInMethod == 'SMS_MFA';
}


export function setSmsMfaSetup({
  session,
  userIdForSrp,
  }: {
    session: string;
    userIdForSrp: string;
  }
  ){
    const { storage } = configure();
    storage.setItem(
      `Otp.authResult`,
      session
    );
    storage.setItem(
      `userIdForSrp`,
      userIdForSrp
    );
  }

export function authenticatePoolOtpAfterSRP({
  otp,
  tokensCb,
  statusCb,
  }: {
    otp: string;
    tokensCb?: (tokens: TokensFromSignIn) => void | Promise<void>;
    statusCb?: (status: BusyState | IdleState) => void;
  }
  ){
  
  var abort = new AbortController();
  const validateOtp = (async () => {
    console.log("otp",otp);
    const { userPoolId, storage, debug } = configure();
    const userIdForSrp = await storage.getItem(`userIdForSrp`) || "";
  const challengeResponse2 = {
    SMS_MFA_CODE: otp,
    USERNAME: userIdForSrp,
  };
  var isResendOtp = localStorage.getItem('isResendOtp');
  const  clientMetadata = {
    "signInMethod": "SMS_MFA",
    "resendOtp": isResendOtp || "false",
  }
  
  const session = await storage.getItem(`Otp.authResult`) || "";
  
  const res2 = await respondToAuthChallenge({
    challengeName: "SMS_MFA",
    challengeResponses: challengeResponse2,
    session: session,
    clientMetadata: clientMetadata,
    abort: abort.signal,
});

    const tokens = await handleAuthResponse({
      authResponse: res2,
      username: userIdForSrp,
      abort: abort.signal,
      statusCb
    });

    tokensCb
      ? await tokensCb(tokens)
      : await defaultTokensCb({ tokens, abort: abort.signal });
    statusCb?.("SIGNED_IN_WITH_PASSWORD");
    return tokens;
  })();
  // return otp;
  return {
    validateOtp,
    abort: () => abort.abort(),
  };
}

export function authenticateOtpAfterSRP({
  otp,
  tokensCb,
  statusCb,
  }: {
    otp: string;
    tokensCb?: (tokens: TokensFromSignIn) => void | Promise<void>;
    statusCb?: (status: BusyState | IdleState) => void;
  }
  ){
  
  var abort = new AbortController();
  const validateOtp = (async () => {
    console.log("otp",otp);
    const { userPoolId, storage, debug } = configure();
    const userIdForSrp = await storage.getItem(`userIdForSrp`) || "";
  const challengeResponse2 = {
    ANSWER: otp,
    USERNAME: userIdForSrp,
  };
  var isResendOtp = localStorage.getItem('isResendOtp');
  const  clientMetadata = {
    "signInMethod": "SMS_MFA",
    "resendOtp": isResendOtp || "false",
  }
  
  const session = await storage.getItem(`Otp.authResult`) || "";
  
  const res2 = await respondToAuthChallenge({
    challengeName: "CUSTOM_CHALLENGE",
    challengeResponses: challengeResponse2,
    session: session,
    clientMetadata: clientMetadata,
    abort: abort.signal,
});

    const tokens = await handleAuthResponse({
      authResponse: res2,
      username: userIdForSrp,
      abort: abort.signal,
      statusCb
    });
    // const tokens = await handleAuthResponse({
    //   authResponse: res2,
    //   username,
    //   smsMfaCode,
    //   newPassword,
    //   customChallengeAnswer,
    //   clientMetadata,
    //   abort: abort.signal,
    // });
    tokensCb
      ? await tokensCb(tokens)
      : await defaultTokensCb({ tokens, abort: abort.signal });
    statusCb?.("SIGNED_IN_WITH_PASSWORD");
    return tokens;
  })();
  // return otp;
  return {
    validateOtp,
    abort: () => abort.abort(),
  };
}