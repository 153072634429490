import React, { useEffect, useState } from 'react';
import { usePasswordless } from "amazon-cognito-passwordless-auth/react";
import { v4 as uuidv4 } from 'uuid';
import { useLocation } from 'react-router';


const ClientRedirectionHandler: React.FC = () => {
  const {
    signInStatus,
    tokens,
    tokensParsed,
  } = usePasswordless();
  var successRedirectionUrl = "";
  const [apiResponse, setApiResponse] = useState(null);
  var [clientDetails, setClientDetails] = useState<any>(null);
  var [randomProductDetails, setRandomProductDetails] = useState<any>(null);
  const location = useLocation();
  interface ProductDetails{
    mainImage: string;
  }

  const productDetailsMap: { [key: string]: ProductDetails } = {
    Deployer: {
      mainImage: '/all-assets/deployer.jpg'
  },
  StructureCMS: {
    mainImage:'/all-assets/structure.jpg'
  },
  EmailDesigner: {
      mainImage: '/all-assets/emaildesigner.jpg'
  },
  ValidatePlus: {
      mainImage: '/all-assets/validateplus.jpg'
  },
  // RithmCrm: {
  //     mainImage: '/all-assets/rithmcrm.jpg'
  // },
  // CopyApproval: {
  //     mainImage: '/all-assets/copyapproval.jpg'
  // },
  Multiplizer: {
      mainImage: '/all-assets/multiplizer.jpg'
  },
  IgniteCdn: {
      mainImage: '/all-assets/ignitecdn.jpg'
  },
  // AccountConsole: {
  //   mainImage: '/all-assets/accountconsole.jpg'
  // },
  MrSignIn: {
    mainImage: '/all-assets/mrsignin.jpg'
  },
  StructureCMSLms: {
    mainImage: '/all-assets/RithmUniversity.jpg'
  }
  };

  useEffect(()=> {
    const getQueryParam = (param: string) => {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      return urlParams.get(param);
    };

    document.body.style.overflow = 'hidden';

    const clientDetailsMap: { [key: string]: { cardLogo: string; cardName: string;} } = {
      AccountConsole: { cardLogo: '/all-assets/logos/accountconsole_logo.svg', cardName: 'AccountConsole'},
      StructureCMS: { cardLogo: '/all-assets/logos/original_structurecms.logo.png', cardName: 'StructureCMS' },
      Deployer: {cardLogo: '/all-assets/logos/original_deployer.png', cardName: 'Deployer'},
      EmailDesigner: {cardLogo: '/all-assets/logos/original_email_designer.png', cardName: 'EmailDesigner'},
      ValidatePlus: {cardLogo: '/all-assets/logos/original_validateplus.png', cardName: 'ValidatePlus'},
      RithmCrm: {cardLogo: '/all-assets/logos/original_rithmcrm.png', cardName: 'RithmCrm'},
      CopyApproval: {cardLogo: '/all-assets/logos/original_copyapproval.logo.svg', cardName: 'CopyApproval'},
      Multiplizer: {cardLogo: '/all-assets/logos/original_multiplizer.logo.svg', cardName: 'Multiplizer'},
      IgniteCdn: {cardLogo: '/all-assets/logos/original_ignite_cdn.png', cardName: 'IgniteCdn'},
      MrSignIn: { cardLogo: '/all-assets/logos/mr-signin.svg', cardName: 'MrSignIn'},
      StructureCMSLms: { cardLogo: '/all-assets/logos/rithmuniversity.png', cardName: 'RithmUniversity'},
      Maestro: { cardLogo: '/all-assets/logos/maestro.png', cardName: 'Maestro'}
    };

    var clientName = getQueryParam('clientName');
    if (clientName != null){
      
      if(clientDetailsMap[clientName]){
        setClientDetails(clientDetailsMap[clientName]);
      } else {
        setClientDetails(clientDetailsMap['StructureCMS']);
      }
      // if(clientName == 'StructureCMSLms'){
      //   setClientDetails(clientDetailsMap['StructureCMS']);
      // }
      // else{
      //   setClientDetails(clientDetailsMap[clientName]);
      // }
      // if(clientName == 'StructureCMS' || clientName == 'AccountConsole' || clientName == 'StructureCMSLms'){
      //   delete productDetailsMap['StructureCMS'];
      // }
      const filteredProducts = Object.keys(productDetailsMap)
      .filter(product => product !== clientName)
      .map(product => productDetailsMap[product]);

        const randomIndex = Math.floor(Math.random() * filteredProducts.length);
        setRandomProductDetails(filteredProducts[randomIndex]);
    };

    var givenRedirectUrl = localStorage.getItem('givenRedirectUrl');
    var mrsClientid = localStorage.getItem('mrsClientid');
    var lmsSiteId = localStorage.getItem('lmsSiteId');
    if(mrsClientid && givenRedirectUrl){
      const generateAuthCode = async () => {
        console.log("setSuccessRedirectionUrl before is ", successRedirectionUrl);
        const apiUrl = import.meta.env.VITE_STORE_TOKEN_LAMBDA_URL + 'register-authenticator/generate-auth';
        const bearerToken = tokens?.idToken;
        var username = tokensParsed?.accessToken.username;
        const requestBody = {
          clientId: mrsClientid,
          redirectUrl: givenRedirectUrl,
        };

        try {
          const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${bearerToken}`,
            },
            body: JSON.stringify(requestBody),
          });

          if (!response.ok) {
            throw new Error('Network response was not ok');
          }

          const data = await response.json();
          var deviceId = localStorage.getItem('deviceId');
          if(deviceId == null){
            deviceId = uuidv4();
            console.log('DeviceId is:', deviceId);
            localStorage.setItem('deviceId', deviceId);
          }
          setApiResponse(data);
          console.log('Auth Code:', data);
          console.log('Auth Code body is:', data?.body);
          console.log('Auth Code body inside authcode:', data?.body?.authCode);
          var authCode = data?.body?.authCode
          var registerValue = "PasswordlessRegistration";
          const queryParams = new URLSearchParams(location.search);
          const invite_encoded_token = queryParams.get('invite_encoded_token');
          const subscriptionId = queryParams.get('subscription_id');
          const type = queryParams.get('type');
          // var registerValue = "passwordless_auth";
          // var username = tokensParsed?.accessToken.username;
          successRedirectionUrl = givenRedirectUrl + "?authCode=" + authCode + "&userName=" + username + "&lmsSiteId=" + lmsSiteId+ "&deviceId=" + deviceId +"&invite_token=" + invite_encoded_token + "&subscription_id=" + subscriptionId + "&type=" + type;
          if (signInStatus && signInStatus === "SIGNED_IN" && tokens && (registerValue !== "passwordless_auth")){
            localStorage.removeItem('givenRedirectUrl');
            localStorage.removeItem('mrsClientid');
            window.location.href = successRedirectionUrl;
          }
          console.log("setSuccessRedirectionUrl is ", successRedirectionUrl);
        } catch (error) {
          if (error instanceof Error) {
          console.error('Error:', error.message);
          }
        }
      };

      generateAuthCode();
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div className="conatiner main-container">
      <div className="row" style={{height: 'inherit'}}>
        {/* <div className="col-md-7 flex-container">
          {randomProductDetails && (
            <><div>
                <img src={randomProductDetails.mainImage} className="img-fluid"/>

            </div></>
          )}
        </div> */}
        <div
          className="col-md-7 flex"
          style={{ backgroundImage: `url(${randomProductDetails?.mainImage})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}
        >
        </div>

        <div className="col-md-5 flex-container" style={{background:'white'}}>
          <div className="passwordless-card-container">
            {clientDetails && (
              <img
              src={clientDetails.cardLogo}
              className="passwordless-client-logo"
              />
            )}
            <div className="passwordless-text-center passwordless-customer-name">
              <img
                src="/all-assets/logos/mr-signin.svg"
                className="passwordless-mrsignin-logo"
              />
            </div>
            {clientDetails && (
              <>
                <div style={{display:'block'}}>
                  <div className="passwordless-loading-spinner" style={{marginRight: '20px'}} />
                  Please wait you will be redirected to {clientDetails.cardName}....
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ClientRedirectionHandler;
