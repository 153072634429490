import { useState } from "react"
import "./header.css";
import "./StepUpAuth.css";
import Spinner from "../amazon-cognito-passwordless-auth/react/spinner";
import { useToast } from "../amazon-cognito-passwordless-auth/react/ToastContextProvider";
import { getEnvironmentValueOf } from './main'
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router';

const OtpModal = ({onCloseOtpModal,phoneNumber,countryCode,email,setStepNext}: any) => {
    const [smsOtp, setSmsOtp] = useState("");
    const [isWrongSmsOtp, setIsWrongSmsOtp] = useState<boolean>(false);
    const [lastError, setLastError] = useState<Error>();
    const { showToast } = useToast();
    const [spinner,setSpinner ] = useState(false)
    const navigate = useNavigate();
    const location = useLocation();

    const handleChange= (e:any) => {
      setIsWrongSmsOtp(false);
      setLastError(undefined);
      setSmsOtp(e.target.value)
    }

    const handleOtpVerification = async  () => {
      const apiBaseURL = getEnvironmentValueOf("VITE_STORE_TOKEN_LAMBDA_URL");
      var mrsigninUrl = getEnvironmentValueOf("VITE_MRSIGNIN_URL");
      const verifyOtpApi = apiBaseURL + 'user/verify-mobile-verification-otp'
      const redirection_invite_url = window.location.search.includes('email')
      if(redirection_invite_url){
        const queryParams = new URLSearchParams(location.search);
        const invite_encoded_token = queryParams.get('code');
        const redirectUrl = queryParams.get('redirectUrl');
        const mrsclientid = queryParams.get('mrsClientId');
        const clientName = queryParams.get('clientName');
        if(redirectUrl && mrsclientid && clientName && invite_encoded_token){
          mrsigninUrl = mrsigninUrl + "/clientRedirectionHandler/?redirectUrl="+ redirectUrl +"&mrsClientId="+ mrsclientid +"&clientName="+ clientName +"&invite_encoded_token="+ invite_encoded_token
        }
      }
      const requestBody = {
        "username" : email,
        "phonenumber" : countryCode+phoneNumber,
        "otp" : smsOtp,
        redirectUri : mrsigninUrl
      }
      try {
        const response = await fetch(verifyOtpApi, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
            },
          body : JSON.stringify(requestBody),
        });
        if(!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        return data;
      } catch (error) {
        if (error instanceof Error) {
          setLastError(error)
          console.error('Error:', error.message);
        }
      }
    }

  return (<>
    <div className="fade show" data-backdrop="static" data-keyboard="false" tabIndex={-1} style={{display: "block"}} aria-labelledby="staticBackdropLabel" aria-hidden="true" role='dialog' >
      <div className="modal-dialog modal-lg">
        <div className="modal-content-m">
          <form
          onSubmit={async (e) => {
            setSpinner(true)
            e.preventDefault();
            const response = handleOtpVerification();
            const data = await response;
            if(data){
              if (data.statusCode == 400){
                setLastError(undefined)
                setIsWrongSmsOtp(true);
              }else{
                setStepNext({First : false,Second : false,Last : true})
                showToast('User created successfully. Please check your mail for activation.', 'success');
                // navigate('/')
              }
            }
            setSpinner(false)
          }}
          >
            <div className="modal-header-m">
              <h5 className="modal-title" id="staticBackdropLabel">OTP VERIFICATION</h5>
              {/* <button type="button" onClick={() => {onCloseOtpModal()}} className="closek" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button> */}
            </div>
            <div className="modal-body-m">
              <div className="row" style={{justifyContent:"center"}}>
                <div style={{textAlign:"center",paddingBottom:"1rem"}} >Please enter the OTP, sent to your registered mobile number:</div>
                <input
                  id="sms-otp"
                  type="text"
                  inputMode="numeric"
                  pattern="[0-9]{6}"
                  autoComplete="one-time-code"
                  placeholder="OTP code"
                  style={{lineHeight: "2.15",width: "20rem"}}
                  autoFocus
                  title="OTP Code: should be 6 numbers"
                  value={smsOtp}
                  onChange={handleChange}
                  maxLength={6}
                ></input>
                <div className="step-up-auth-error" style={{position: "static"}}>
                  {!lastError && isWrongSmsOtp && (
                    <div>The one time passcode you entered is invalid. Please try again.</div>
                  )}
                  {lastError && <div>{lastError.message}</div>}
                </div>
              </div>
            </div>
            <div className="modal-footer-m">
              <button type="button" onClick={()=>{onCloseOtpModal()}} className="btn btn-danger" data-dismiss="modal">Back</button>
              <button
                className="btn btn-primary"
                type="submit"
                disabled={
                  smsOtp.length < 6
                }
                >
                Submit and Verify Email
                </button>
            </div>
          </form>
        </div>
      </div>
    </div>
    {spinner &&(<Spinner/>)}
  </>)
}

export default OtpModal;