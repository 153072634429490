const Support = () => {
    return <>
    <section id="support" className="bg-white ">
      <div className="container pt-5 pb-5">
        <div className="row">

          <div className="col-sm-4 text-center">
              <div className="icon"><img src="https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5d0a/6f38/6970/2d4c/7d19/3a00/original_icon.graduation-cap-light.png?1560964920" alt="icon"/></div>
              <a href="https://rithmuniversity.com">
              <h4>FAQS</h4></a>
              <p>Learn about Market Rithm's SaaS marketing platforms.</p>
          </div>

          <div className="col-sm-4 text-center">
              <div className="icon"><img src="https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5d0a/6f73/6970/2d45/cecc/3d00/original_icon.comments-light.png?1560964979" alt="icon"/></div>
              <a href="https://support.structure.site">
              <h4>SUPPORT</h4></a>
              <p>Get support from the Market Rithm team. </p>
          </div>

          <div className="col-sm-4 text-center">
              <div className="icon"><img src="https://mr.cdn.ignitecdn.com/client_assets/marketrithm/media/picture/5d0a/6fac/6970/2d4c/7d00/3b00/original_icon.headset-light.png?1560965036" alt="icon"/></div>
              <a href="https://marketrithm.com/contact">
              <h4>CONTACT</h4></a>
              <p>Just want someone to talk to? Contact the Market Rithm team.</p>
          </div>

        </div>
      </div>
    </section>
    </>
}

export default Support;