import { getEnvironmentValueOf } from "./main";

const Footer = () => {
  const structureUrl = getEnvironmentValueOf("VITE_STRUCTURECMS_URL");
  const acUrl = getEnvironmentValueOf("VITE_ACCOUNT_CONSOLE_URL");
    return <>
    <footer>
      <div className="container">
        <div className="row mb-4">
          <div className="col-md-3 col-sm-5">
            <div className="brand">Market Rithm, Inc.</div>
          </div>
          <div className="col-sm text-right">
            <div className="networks text-right">
              <a href="#" className="network net-fb" target="_blank" title="Facebook"><svg className="svg-inline--fa fa-facebook-f" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook-f" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" data-fa-i2svg=""><path fill="currentColor" d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"></path></svg></a>
              <a href="#" className="network net-tw" target="_blank" title="Twitter"><svg className="svg-inline--fa fa-twitter" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path></svg></a>
              <a href="#" className="network net-yt" target="_blank" title="YouTube"><svg className="svg-inline--fa fa-youtube" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="youtube" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M549.655 124.083c-6.281-23.65-24.787-42.276-48.284-48.597C458.781 64 288 64 288 64S117.22 64 74.629 75.486c-23.497 6.322-42.003 24.947-48.284 48.597-11.412 42.867-11.412 132.305-11.412 132.305s0 89.438 11.412 132.305c6.281 23.65 24.787 41.5 48.284 47.821C117.22 448 288 448 288 448s170.78 0 213.371-11.486c23.497-6.321 42.003-24.171 48.284-47.821 11.412-42.867 11.412-132.305 11.412-132.305s0-89.438-11.412-132.305zm-317.51 213.508V175.185l142.739 81.205-142.739 81.201z"></path></svg></a>
              <a href="#" className="network net-ig" target="_blank" title="Instagram"><svg className="svg-inline--fa fa-linkedin-in" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="linkedin-in" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M100.28 448H7.4V148.9h92.88zM53.79 108.1C24.09 108.1 0 83.5 0 53.8a53.79 53.79 0 0 1 107.58 0c0 29.7-24.1 54.3-53.79 54.3zM447.9 448h-92.68V302.4c0-34.7-.7-79.2-48.29-79.2-48.29 0-55.69 37.7-55.69 76.7V448h-92.78V148.9h89.08v40.8h1.3c12.4-23.5 42.69-48.3 87.88-48.3 94 0 111.28 61.9 111.28 142.3V448z"></path></svg></a>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-6 col-sm-3">
            <h5>PRODUCTS</h5>
            <ul className="menu-list nav navbar-nav ml-auto menu-list-footer-products"><li className="menu-item-00157 first is_active nav-item" style={{}}><a href={structureUrl} className="structure-cms-link nav-link ">structure cms</a></li><li className="menu-item-00158  is_active nav-item" style={{}}><a href="/deployer" className="deployer-link nav-link ">deployer</a></li><li className="menu-item-00160 last is_active nav-item" style={{}}><a href="/validate-plus" className="validate-plus-link nav-link ">validate plus</a></li></ul>
          </div>
          <div className="col-6 col-sm-3">
            <h5>COMPANY</h5>
            <ul className="menu-list nav navbar-nav ml-auto menu-list-footer-company"><li className="menu-item-00164 first is_active nav-item" style={{}}><a href="/about" className="about-market-rithm-link nav-link ">About Market Rithm</a></li><li className="menu-item-00165  is_active nav-item" style={{display: "none !important"}}><a href="/product-reviews" className="product-reviews-link nav-link ">Product Reviews</a></li><li className="menu-item-00166  is_active nav-item" style={{}}><a href="https://rithmuniversity.com" className="product-how-tos-link nav-link ">Product How-tos</a></li><li className="menu-item-00167  is_active nav-item" style={{display: "none !important"}}><a href="/our-blog" className="our-blog-link nav-link ">Our Blog</a></li><li className="menu-item-00168  is_active nav-item" style={{display: "none !important"}}><a href="/affiliate-program" className="affiliate-program-link nav-link ">Affiliate Program</a></li><li className="menu-item-00169  is_active nav-item" style={{display: "none !important"}}><a href="/press-page" className="press-page-link nav-link ">Press Page</a></li><li className="menu-item-00170 last is_active nav-item" style={{display: "none !important"}}><a href="/we-re-hiring" className="we-re-hiring-link nav-link ">We’re Hiring</a></li></ul>
          </div>
          <div className="col-6 col-sm-3">
            <h5>SUPPORT</h5>
            <ul className="menu-list nav navbar-nav ml-auto menu-list-footer-support"><li className="menu-item-00172 first is_active nav-item" style={{}}><a href={acUrl+"structure_cms/support_ticket/list"} className="contact-support-link nav-link ">Contact support</a></li><li className="menu-item-00173  is_active nav-item" style={{display: "none !important"}}><a href="https://rithmuniversity.com" className="knowledge-base-link nav-link ">Knowledge base</a></li><li className="menu-item-00174  is_active nav-item" style={{display: "none !important"}}><a href="/product-f-a-q" className="product-f-a-q-link nav-link ">Product F.A.Q.</a></li><li className="menu-item-00175 last is_active nav-item" style={{}}><a href="/sitemap" className="sitemap-link nav-link ">Sitemap</a></li></ul>
          </div>
          <div className="col-6 col-sm-3">
            <h5>POLICY</h5>
            <ul className="menu-list nav navbar-nav ml-auto menu-list-footer-policy"><li className="menu-item-00178 first is_active nav-item" style={{}}><a href="/privacy-compliance" className="privacy-policy-link nav-link ">Privacy policy</a></li><li className="menu-item-00179 last is_active nav-item" style={{display: "none !important"}}><a href="/terms" className="terms-link nav-link ">Terms</a></li></ul>
            <h5>SALES</h5>
            <ul className="menu-list nav navbar-nav ml-auto menu-list-sales"><li className="menu-item-00181 first is_active nav-item" style={{}}><a href="/contact" className="corporate-sales-link nav-link ">Corporate sales</a></li><li className="menu-item-00182  is_active nav-item" style={{}}><a href="/contact" className="educational-discounts-link nav-link ">Educational discounts</a></li><li className="menu-item-00183  is_active nav-item" style={{}}><a href="/contact" className="non-profit-discounts-link nav-link ">Non-Profit discounts</a></li><li className="menu-item-00184 last is_active nav-item" style={{}}><a href="/contact" className="partnership-offers-link nav-link ">Partnership offers</a></li></ul>
          </div>
        </div>

        <div className="row">
          <div className="col-sm-8">
            <div className="legal">
              © 2021, Market Rithm, Inc., all rights reserved | 1750 Tysons Blvd, 15th Floor, McLean, VA 22102&nbsp; | 703.574.4011
            </div>
          </div>
          <div className="col-sm-4 text-right">
            <a href={structureUrl} className="spinc" target="_blank" title="Powered by MarketRithm">marketrithm, inc.</a>
            <a href="https://www.w3.org/html/logo/" className="html5" target=""><svg className="svg-inline--fa fa-html5" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="html5" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M0 32l34.9 395.8L191.5 480l157.6-52.2L384 32H0zm308.2 127.9H124.4l4.1 49.4h175.6l-13.6 148.4-97.9 27v.3h-1.1l-98.7-27.3-6-75.8h47.7L138 320l53.5 14.5 53.7-14.5 6-62.2H84.3L71.5 112.2h241.1l-4.4 47.7z"></path></svg></a>
            <a href="https://www.w3.org/Style/CSS/Overview.en.html" className="css3" target=""><svg className="svg-inline--fa fa-css3-alt" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="css3-alt" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M0 32l34.9 395.8L192 480l157.1-52.2L384 32H0zm313.1 80l-4.8 47.3L193 208.6l-.3.1h111.5l-12.8 146.6-98.2 28.7-98.8-29.2-6.4-73.9h48.9l3.2 38.3 52.6 13.3 54.7-15.4 3.7-61.6-166.3-.5v-.1l-.2.1-3.6-46.3L193.1 162l6.5-2.7H76.7L70.9 112h242.2z"></path></svg></a>
            <a href="https://getbootstrap.com/" className="bootstrap" target=""><svg className="svg-inline--fa fa-bootstrap" aria-hidden="true" focusable="false" data-prefix="fab" data-icon="bootstrap" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" data-fa-i2svg=""><path fill="currentColor" d="M333.5,201.4c0-22.1-15.6-34.3-43-34.3h-50.4v71.2h42.5C315.4,238.2,333.5,225,333.5,201.4z M517,188.6 c-9.5-30.9-10.9-68.8-9.8-98.1c1.1-30.5-22.7-58.5-54.7-58.5H123.7c-32.1,0-55.8,28.1-54.7,58.5c1,29.3-0.3,67.2-9.8,98.1 c-9.6,31-25.7,50.6-52.2,53.1v28.5c26.4,2.5,42.6,22.1,52.2,53.1c9.5,30.9,10.9,68.8,9.8,98.1c-1.1,30.5,22.7,58.5,54.7,58.5h328.7 c32.1,0,55.8-28.1,54.7-58.5c-1-29.3,0.3-67.2,9.8-98.1c9.6-31,25.7-50.6,52.1-53.1v-28.5C542.7,239.2,526.5,219.6,517,188.6z M300.2,375.1h-97.9V136.8h97.4c43.3,0,71.7,23.4,71.7,59.4c0,25.3-19.1,47.9-43.5,51.8v1.3c33.2,3.6,55.5,26.6,55.5,58.3 C383.4,349.7,352.1,375.1,300.2,375.1z M290.2,266.4h-50.1v78.4h52.3c34.2,0,52.3-13.7,52.3-39.5 C344.7,279.6,326.1,266.4,290.2,266.4z"></path></svg></a>
          </div>
        </div>
      </div>
    </footer>
  </>
}
export default Footer;
