import React from 'react';

function Spinner(): JSX.Element {
  return (<>
    <div className="modal fade show" role="dialog" style={{ display: 'block' }}>
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body inline-properties flex-container">
              <div className="passwordless-loading-spinner" />
              <div>Please wait ...</div>
          </div>
        </div>
      </div>
    </div></>);
}

export default Spinner;
