import { useState } from "react";
import { usePasswordless } from "./hooks";
import { useNavigate } from "react-router-dom";
import { adminChangePassword } from "../common";
import './reset.css';
import ForgotPasswordEmail from "./ForgotPasswordEmail";
import Spinner from "./spinner";
import { Passwordless } from "amazon-cognito-passwordless-auth/react/components";

export const PasswordReset = (props: { brand: {
    customerLogoUrl: string | undefined; backgroundImageUrl: string | undefined; 
  }; }) => {
    const {
      tokens,
      signInStatus,
      signingInStatus: beforeMfaUserSigninStatus
    } = usePasswordless();
    const [newPassword, setNewPassword] = useState("");
    const [confirmNewPassword, setConfirmNewPassword] = useState("");
    const [error,setError] = useState(false);
    const [errTxt,setErrorTxt] = useState('');
    const [isLoading,setIsLoading] = useState(false);
    const navigate = useNavigate();
    const pass_regex = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;'!@#\$%^&*_+\-=|(){}<>])[0-9a-zA-Z.,:;'!@#\$%^&*_+\-=|(){}<>]{8,}$/;
    async function submitHandler(event: { preventDefault: () => void; })
    {
      event.preventDefault();
      if(newPassword == '' ){
        setError(true);
        setErrorTxt("please enter the new password!")
      }else{
        if(!/.{8,}/.test(newPassword)){
          setError(true);
          setErrorTxt( 'Password is too short (minimum is 8 characters)');
        }else if(!((/(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.,:;'!@#\$%^&*_+-=|(){}<>])/.test(newPassword)) && (pass_regex.test(newPassword)))){
          setError(true);
          setErrorTxt( 'Password should contain atleast one lowercase, uppercase, digit and special character.');
        }
        else if(!pass_regex.test(newPassword)){
          setError(true);
          setErrorTxt( 'Password should contain minimum 8 characters with atleast one lowercase, uppercase, digit and special character');
        }
        else if(confirmNewPassword == ''){
          setError(true);
          setErrorTxt("please enter the confirm new password!")
        }else if (newPassword !== confirmNewPassword) {
          setError(true);
          setErrorTxt( 'The passwords does not match');
        }else{
          setError(false);
          setIsLoading(true);
          try {
            const response = adminChangePassword({
              idToken: tokens?.idToken,
              password: newPassword
            });
            await response.resetPassword;
          } catch (error) {
            console.error('Error resetting password:', error);
          } finally {
            setIsLoading(false);
            navigate("/");
          }
        }
      }
    }
  
  return (<>{(beforeMfaUserSigninStatus === "MAGIC_LINK_VERIFIED_VALIDATING_MFA" || ( beforeMfaUserSigninStatus == "SIGNING_IN_WITH_LINK" || beforeMfaUserSigninStatus == "CHECKING_FOR_SIGNIN_LINK")) && ( <Passwordless brand={props.brand}/>) }
    {(beforeMfaUserSigninStatus != "MAGIC_LINK_VERIFIED_VALIDATING_MFA" && beforeMfaUserSigninStatus != "SIGNING_IN_WITH_LINK" && beforeMfaUserSigninStatus != "CHECKING_FOR_SIGNIN_LINK") && ((signInStatus == 'SIGNED_IN'|| signInStatus == 'CHECKING')) && (<div className="conatiner main-container">
      <div className="row" style={{height: 'inherit',fontFamily: "arboria, sans-serif",fontSize: "18px"}}>
        <div className="col-md-7" style={{paddingRight: 0}}>
          <div className="passwordless-main-container">
            <img
              src={props.brand.backgroundImageUrl}
              className="passwordless-main-background-image"
            />
          </div>
        </div>
        <div className="col-md-5" style={{display: 'flex',flexDirection: 'column',alignItems: 'center', justifyContent:'space-evenly'}}>
          <div className="container-login100">
            <div className="wrap-login100">
              <form
                className="login100-form ">
                <img
                  src={props.brand.customerLogoUrl}
                  className="d-block ml-3 mr-3 mb-5"
                />
                <div className='wrap-input100'>
                  <input
                    className="input100 border-bottom border-top-0 border-right-0 border-left-0 rounded-1"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                    placeholder="New Password"
                    type="password"
                  />
                  <span className='focus-input100'></span>
                </div>
                <div className='wrap-input100'>
                  <input
                    className="input100 border-bottom border-top-0 border-right-0 border-left-0 rounded-1"
                    value={confirmNewPassword}
                    onChange={(e) => setConfirmNewPassword(e.target.value)}
                    placeholder="Confirm New Password"
                    type="password"
                  />
                  <span className='focus-input100'></span>
                </div>
                {error &&(<span className='small bold' id='form-error' style={{color:"red"}}>{errTxt}</span>)}
                <div className="container-login100-form-btn">
                  <button className="login100-form-btn"
                  onClick={(event) => submitHandler(event)}
                  >
                    SUBMIT
                  </button>
                </div>
                <div className='text-center p-t-46 p-b-20'>
                  <span><br/></span>
                  <span className='txt2'>
                    or
                    <a href="/">Go Back</a>
                  </span>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {(isLoading)&&(<Spinner/>)}
    </div>)}
    {((signInStatus == 'NOT_SIGNED_IN' || signInStatus == 'SIGNING_IN') && (beforeMfaUserSigninStatus != "MAGIC_LINK_VERIFIED_VALIDATING_MFA" && beforeMfaUserSigninStatus != "SIGNING_IN_WITH_LINK")) && (<ForgotPasswordEmail brand={props.brand} />)}</>
  );
  };

  export default PasswordReset;