import { Session } from './cognito-api';
import { configure, } from "./config.js";
import { IdleState, BusyState, TokensFromSignIn, busyState } from "./model.js";
import { getEnvironmentValueOf } from '../src/main'

export async function createUser({
    username,
    password,
    userAttributes,
    // clientMetadata,
    // validationData,
    currentStatus,
    statusCb,
  }: {
    username: string;
    password: string;
    userAttributes?: { };
    currentStatus?: BusyState | IdleState;
    statusCb?: (status: BusyState | IdleState) => void;
    // clientMetadata?: Record<string, string>;
    // validationData?: { name: string; value: string }[];
  }) {
    const { clientId, storage, debug } = configure();
    if (currentStatus && busyState.includes(currentStatus as BusyState)) {
      throw new Error(
        `Can't request sign-in link while in status ${currentStatus}`
      );
    }
    console.log(username,password,userAttributes);
    statusCb?.("REQUESTING_SIGNIN_LINK");
    const abort = new AbortController();
    const apiBaseURL = getEnvironmentValueOf("VITE_STORE_TOKEN_LAMBDA_URL");
    const UserPreRegisterApiUrl = apiBaseURL + '/user/pre-register'
    const mrsigninUrl = getEnvironmentValueOf("VITE_MRSIGNIN_URL");
    const requestBody = {
      username,
      password,
      redirectUri: mrsigninUrl,
      userAttributes
    };
  const signInLinkRequested = (async () => {
    try {
      const response = await fetch(UserPreRegisterApiUrl, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestBody),
      }
      );
  
      if (!response.ok) {
        throw new Error('Error creating the User, try later or contact Administrator');
      }
  
      const data = await response.json();
      statusCb?.("SIGNIN_LINK_REQUESTED_FOR_REG");
      console.log('Response body is:', data?.body);
      if (!data.statusCode || data.statusCode !== 200) {
        throw new Error('Error creating the User, try later or contact Administrator');
      }
      // if(data.statusCode === 200 && data?.body && data?.body.status === "success" && data?.body.message === "User created successfully" ){
      //   return {success: true, username: username, password: password}
      //   statusCb?.("SIGNIN_LINK_REQUESTED");
      // }else{
      //   return {success: false, username: username, password: password}
      // }
    } catch (err) {
      debug?.(err);
      currentStatus && statusCb?.("SIGNIN_LINK_REQUEST_FAILED");
      throw err;
    }
  })();
  return {
    signInLinkRequested,
    abort: () => abort.abort(),
  };
};