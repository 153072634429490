import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePasswordless } from "../amazon-cognito-passwordless-auth/react/hooks";


const SignOut: React.FC = (props) => {
  
  const {
    signOut,
    globalSignOut,
    tokens,
    tokensParsed
  } = usePasswordless();

  const navigate = useNavigate();
  var logoutClientName : any;
  var deviceIdForDeviceSignOut :any
  var emailFromClientApp : any

  useEffect(() => {
    const getQueryParam = (param: string) => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get(param);
    };
    // var givenRedirectUrl = getQueryParam('redirect_url');
    deviceIdForDeviceSignOut = getQueryParam('deviceId');
    logoutClientName = getQueryParam('clientName');
    emailFromClientApp = getQueryParam('email');

    if (emailFromClientApp == tokensParsed?.idToken.email){
      if (deviceIdForDeviceSignOut == null){
        newGlobalSignOut();
      } else {
        onlyDeviceSignOut(deviceIdForDeviceSignOut);
      }
    }
    // navigate('/');
    // if(givenRedirectUrl == null) {
    //     navigate('/');
    // } else {
    //     // navigate(givenRedirectUrl);
    //     window.location.href = givenRedirectUrl;
    // }
  }, [navigate]);

  function onlyDeviceSignOut(deviceId : any){
    if(!deviceId || deviceId == ''){
      deviceId = localStorage.getItem('deviceId');
    }
    if (!logoutClientName || deviceIdForDeviceSignOut == ''){
      const acLogoutApiUrl = import.meta.env.VITE_ACCOUNT_CONSOLE_URL + "logout_user?deviceId=" + deviceId;
      const stLogoutApiUrl = import.meta.env.VITE_STRUCTURECMS_URL + "logout_user?deviceId=" + deviceId;
      const deployerApiUrl = import.meta.env.VITE_DEPLOYER_URL + "logout?deviceId=" + deviceId;
      const maestroApiUrl = import.meta.env.VITE_MAESTRO_URL + "logout/maestro?deviceId=" + deviceId;
      deviceSignOut(deployerApiUrl);
      deviceSignOut(acLogoutApiUrl);
      deviceSignOut(stLogoutApiUrl);
      deviceSignOut(maestroApiUrl);
    }
    signOut();
  }

  function newGlobalSignOut(){
    globalSignOut();
    if (!logoutClientName){
      const acLogoutApiUrl = import.meta.env.VITE_ACCOUNT_CONSOLE_URL + "logout_user";
      const stLogoutApiUrl = import.meta.env.VITE_STRUCTURECMS_URL + "logout_user";
      const deployerApiUrl = import.meta.env.VITE_DEPLOYER_URL + "logout";
      const maestroApiUrl = import.meta.env.VITE_MAESTRO_URL + "logout/maestro";
      deviceSignOut(deployerApiUrl);
      deviceSignOut(acLogoutApiUrl);
      deviceSignOut(stLogoutApiUrl);
      deviceSignOut(maestroApiUrl);
    }
  }
  
  const deviceSignOut = async (url: string) => {
      try {
        const apiUrl = url;
        const bearerToken = tokens?.idToken;
        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${bearerToken}`,
          },
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      } catch (error) {
        if (error instanceof Error) {
        console.error('Error:', error.message);
        }
      }
    };
  return <div>Logging out...</div>;
}


export default SignOut;