import { useEffect } from 'react';
import { getEnvironmentValueOf } from '../src/main'

const useIntercom = (intercomSettings: object) => {
  useEffect(() => {
    const intercomScript = document.createElement('script');
    intercomScript.type = 'text/javascript';
    intercomScript.async = true;
    intercomScript.src = 'https://widget.intercom.io/widget/' + getEnvironmentValueOf("VITE_INTERCOM_SETTINGS_APP_ID");  

    document.body.appendChild(intercomScript);

    intercomScript.onload = () => {
      const w = window;
      const ic = w.Intercom;
      if (typeof ic === 'function') {
        ic('reattach_activator');
        ic('update', intercomSettings);
      } else {
        const i: {
          (command: string, args: object): void;
          q: any[];
          c: (args: any[]) => void;
        } = function (command, args) {
          i.c([...arguments]);
        };

        i.q = [] as any[];

        i.c = function (args: any[]) {
          i.q.push(args);
        };

        w.Intercom = i;

        i('boot', intercomSettings);
      }
    };

    return () => {
      document.body.removeChild(intercomScript);
    };
  }, [intercomSettings]);

};

export default useIntercom;