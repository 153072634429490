import React, { useEffect, useState } from 'react';
import { usePasswordless } from "../amazon-cognito-passwordless-auth/react/hooks";
import { Link } from 'react-router-dom';
import "./header.css"
import { v4 as uuidv4 } from 'uuid';
const Header: React.FC = (props) => {

  const {
    signOut,
    globalSignOut,
    signInStatus,
    tokens,
    tokensParsed,
  } = usePasswordless();
  const [isModalShown, setIsModalShown] = useState(false);
  const [buttonClass, setButtonClass] = useState('btnm btn-lightm btn-blockm progress-timer');

  function signOutHandler(){
    setIsModalShown(true);
  }

  useEffect(() => {
    let timer: number | undefined;
    var deviceId = localStorage.getItem('deviceId');
    if(deviceId == null){
      deviceId = uuidv4();
      localStorage.setItem('deviceId', deviceId);
    }
    if (isModalShown) {
      setButtonClass('btnm btn-lightm btn-blockm progress-timer progress-timer-active');

      timer = setTimeout(() => {
        setButtonClass("btnm btn-lightm btn-blockm progress-timer");
        newGlobalSignOut()
      }, 15000);
    }

    return () => {
      clearTimeout(timer);
      setButtonClass("btnm btn-lightm btn-blockm progress-timer");
    };
  }, [isModalShown]);




  function newSignOut(){
    console.log("new signout method 1929");
   // localStorage.clear();
    signOut();
  }

  function newGlobalSignOut(){
    globalSignOut();
    const acLogoutApiUrl = import.meta.env.VITE_ACCOUNT_CONSOLE_URL + "logout_user";
    const stLogoutApiUrl = import.meta.env.VITE_STRUCTURECMS_URL + "logout_user";
    const deployerApiUrl = import.meta.env.VITE_DEPLOYER_URL + "logout";
    const maestroApiUrl = import.meta.env.VITE_MAESTRO_URL + "logout/maestro";
    deviceSignOut(deployerApiUrl);
    deviceSignOut(acLogoutApiUrl);
    deviceSignOut(stLogoutApiUrl);
    deviceSignOut(maestroApiUrl);
  }

  const deviceSignOut = async (url: string) => {
    try {
      // var deviceId = localStorage.getItem('deviceId');

      // const apiUrl = import.meta.env.VITE_ACCOUNT_CONSOLE_URL + "logout_user?deviceId=" + deviceId;
      // const requestBody = {
      //   deviceId: deviceId,
      // };
      const apiUrl = url;
      const bearerToken = tokens?.idToken;
      const response = await fetch(apiUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${bearerToken}`,
        },
        // mode: 'no-cors',
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      if (error instanceof Error) {
      console.error('Error:', error.message);
      }
    }
  };

  function onlyDeviceSignOut(){
    var deviceId = localStorage.getItem('deviceId');
    const acLogoutApiUrl = import.meta.env.VITE_ACCOUNT_CONSOLE_URL + "logout_user?deviceId=" + deviceId;
    const stLogoutApiUrl = import.meta.env.VITE_STRUCTURECMS_URL + "logout_user?deviceId=" + deviceId;
    const deployerApiUrl = import.meta.env.VITE_DEPLOYER_URL + "logout?deviceId=" + deviceId;
    const maestroApiUrl = import.meta.env.VITE_MAESTRO_URL + "logout/maestro?deviceId=" + deviceId;
    deviceSignOut(deployerApiUrl);
    deviceSignOut(acLogoutApiUrl);
    deviceSignOut(stLogoutApiUrl);
    deviceSignOut(maestroApiUrl);
    signOut();
  }

  return (
    // <nav className="navbar navbar-expand-lg navbar-light">
    //   <div className="container-fluid">
    //     <a className="navbar-brand" href="/">
    //       <div className="logo-container">
    //         <img src="https://mr.cdn.ignitecdn.com/client_assets/acccount_structure/media/themes/img/mr-signin.svg" alt="Logo" className="logo" />
    //       </div>
    //     </a>
    //     <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    //       <span className="navbar-toggler-icon"></span>
    //     </button>
    //     <div className="collapse navbar-collapse" id="navbarNav">
    //       <ul className="navbar-nav ms-auto">
    //         <li className="nav-item dropdown">
    //           <a className="nav-link dropdown-toggle" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
    //             <FaUser className="mr-2 user-icon" size={20} />
    //             <p className="email">{tokensParsed?.idToken.email}</p>
    //           </a>
    //           <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="navbarDropdown">
    //             <li><Link to='/authenticators' className="dropdown-item">Manage Authenticators</Link></li>
    //             <li><Link to='/handleUserPasswordChange' className="dropdown-item">Password Reset</Link></li>
    //             {/* <li><a className="dropdown-item"  data-bs-toggle="modal" data-bs-target="#setUpAuthModal">Show Step Up Auth</a></li> */}
    //             <li><a className="dropdown-item" onClick={() => { newSignOut(); }}>Sign out</a></li>
    //           </ul>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>
    // </nav>
    <>
    <nav className="navbar navbar-expand-lg navbar-light bg-light fixed-top">
      <div className="container">
        <a className="navbar-brand" data-turbolinks="false" href="/">
          <img className="mrsi-logo" src="/all-assets/logos/mr-signin.svg"/>
        </a>
        <button aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation" className="navbar-toggler p-0" data-bs-target="#navbarSupportedContent" data-bs-toggle="collapse" type="button">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent" style={{flexGrow: 0}}>
          <ul className="navbar-nav ml-auto">
            <div className="dropdown">
              <a className="nav-link" data-bs-toggle="dropdown" aria-expanded=  "true" >
                <span className="text-dark">
                  {/* <figure className="profile-picture">
                    <img alt="Admin" className="img-circle" src = {tokensParsed?.idToken['custom:avatar_url']}/>
                  </figure>&nbsp; */}
                  {tokensParsed?.idToken['custom:firstname'] +' '+tokensParsed?.idToken['custom:lastname']}&nbsp;
                </span>
                <svg style={{display: "var(--fa-display, inline-block)",height: "1em",overflow: "visible",verticalAlign: "-0.125em"}}className="svg-inline--fa fa-angle-double-down text-danger" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="angle-double-down" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M246.6 470.6c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 402.7 361.4 265.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3l-160 160zm160-352l-160 160c-12.5 12.5-32.8 12.5-45.3 0l-160-160c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0L224 210.7 361.4 73.4c12.5-12.5 32.8-12.5 45.3 0s12.5 32.8 0 45.3z"></path></svg>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link className="dropdown-item-m" to="/authenticators"><svg className="svg-inline--fa fa-fingerprint" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="fingerprint" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M48 256C48 141.1 141.1 48 256 48c63.1 0 119.6 28.1 157.8 72.5c8.6 10.1 23.8 11.2 33.8 2.6s11.2-23.8 2.6-33.8C403.3 34.6 333.7 0 256 0C114.6 0 0 114.6 0 256v40c0 13.3 10.7 24 24 24s24-10.7 24-24V256zm458.5-52.9c-2.7-13-15.5-21.3-28.4-18.5s-21.3 15.5-18.5 28.4c2.9 13.9 4.5 28.3 4.5 43.1v40c0 13.3 10.7 24 24 24s24-10.7 24-24V256c0-18.1-1.9-35.8-5.5-52.9zM256 80c-19 0-37.4 3-54.5 8.6c-15.2 5-18.7 23.7-8.3 35.9c7.1 8.3 18.8 10.8 29.4 7.9c10.6-2.9 21.8-4.4 33.4-4.4c70.7 0 128 57.3 128 128v24.9c0 25.2-1.5 50.3-4.4 75.3c-1.7 14.6 9.4 27.8 24.2 27.8c11.8 0 21.9-8.6 23.3-20.3c3.3-27.4 5-55 5-82.7V256c0-97.2-78.8-176-176-176zM150.7 148.7c-9.1-10.6-25.3-11.4-33.9-.4C93.7 178 80 215.4 80 256v24.9c0 24.2-2.6 48.4-7.8 71.9C68.8 368.4 80.1 384 96.1 384c10.5 0 19.9-7 22.2-17.3c6.4-28.1 9.7-56.8 9.7-85.8V256c0-27.2 8.5-52.4 22.9-73.1c7.2-10.4 8-24.6-.2-34.2zM256 160c-53 0-96 43-96 96v24.9c0 35.9-4.6 71.5-13.8 106.1c-3.8 14.3 6.7 29 21.5 29c9.5 0 17.9-6.2 20.4-15.4c10.5-39 15.9-79.2 15.9-119.7V256c0-28.7 23.3-52 52-52s52 23.3 52 52v24.9c0 36.3-3.5 72.4-10.4 107.9c-2.7 13.9 7.7 27.2 21.8 27.2c10.2 0 19-7 21-17c7.7-38.8 11.6-78.3 11.6-118.1V256c0-53-43-96-96-96zm24 96c0-13.3-10.7-24-24-24s-24 10.7-24 24v24.9c0 59.9-11 119.3-32.5 175.2l-5.9 15.3c-4.8 12.4 1.4 26.3 13.8 31s26.3-1.4 31-13.8l5.9-15.3C267.9 411.9 280 346.7 280 280.9V256z"></path></svg>&nbsp; Manage Authenticators</Link>

                {/* <a className="dropdown-item" href="https://rithmuniversity.com" target="_blank">
                  <svg className="svg-inline--fa fa-graduation-cap" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="graduation-cap" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><g className="fa-duotone-group"><path className="fa-secondary" fill="currentColor" d="M325.6 139.8c8.3-3.1 12.5-12.3 9.4-20.6s-12.3-12.5-20.6-9.4L154.9 169.6C92.4 193.1 50.4 251.8 48.1 318.2c-.1 .6-.1 1.2-.1 1.8c0 28.4-10.8 57.8-22.3 80.8c-6.5 13-13.9 25.8-22.5 37.6C0 442.7-.9 448.3 .9 453.4s6 8.9 11.2 10.2l64 16c4.2 1.1 8.7 .3 12.4-2s6.3-6.1 7.1-10.4c8.6-42.8 4.3-81.2-2.1-108.7c-3.2-14-7.5-28.3-13.4-41.5c2.8-52.5 36.4-98.7 86-117.3l159.4-59.8z"></path><path className="fa-primary" fill="currentColor" d="M320 32c-8.1 0-16.1 1.4-23.7 4.1L15.8 137.4C6.3 140.9 0 149.9 0 160s6.3 19.1 15.8 22.6l77.4 27.9c16.8-17.8 37.8-31.9 61.7-40.9l159.4-59.8c8.3-3.1 17.5 1.1 20.6 9.4s-1.1 17.5-9.4 20.6L166.2 199.6c-14.6 5.5-27.8 13.4-39.3 23.1l169.5 61.2c7.6 2.7 15.6 4.1 23.7 4.1s16.1-1.4 23.7-4.1L624.2 182.6c9.5-3.4 15.8-12.5 15.8-22.6s-6.3-19.1-15.8-22.6L343.7 36.1C336.1 33.4 328.1 32 320 32zM128 408c0 35.3 86 72 192 72s192-36.7 192-72L496.7 262.6 354.5 314c-11.1 4-22.8 6-34.5 6s-23.5-2-34.5-6L143.3 262.6 128 408z"></path></g></svg>
                  Rithm University
                </a>

                <a className="dropdown-item text-danger" data-toggle="modal" href="#support-modal">
                  <svg className="svg-inline--fa fa-life-ring" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="life-ring" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M367.2 412.5C335.9 434.9 297.5 448 256 448s-79.9-13.1-111.2-35.5l58-58c15.8 8.6 34 13.5 53.3 13.5s37.4-4.9 53.3-13.5l58 58zm90.7 .8c33.8-43.4 54-98 54-157.3s-20.2-113.9-54-157.3c9-12.5 7.9-30.1-3.4-41.3S425.8 45 413.3 54C369.9 20.2 315.3 0 256 0S142.1 20.2 98.7 54c-12.5-9-30.1-7.9-41.3 3.4S45 86.2 54 98.7C20.2 142.1 0 196.7 0 256s20.2 113.9 54 157.3c-9 12.5-7.9 30.1 3.4 41.3S86.2 467 98.7 458c43.4 33.8 98 54 157.3 54s113.9-20.2 157.3-54c12.5 9 30.1 7.9 41.3-3.4s12.4-28.8 3.4-41.3zm-45.5-46.1l-58-58c8.6-15.8 13.5-34 13.5-53.3s-4.9-37.4-13.5-53.3l58-58C434.9 176.1 448 214.5 448 256s-13.1 79.9-35.5 111.2zM367.2 99.5l-58 58c-15.8-8.6-34-13.5-53.3-13.5s-37.4 4.9-53.3 13.5l-58-58C176.1 77.1 214.5 64 256 64s79.9 13.1 111.2 35.5zM157.5 309.3l-58 58C77.1 335.9 64 297.5 64 256s13.1-79.9 35.5-111.2l58 58c-8.6 15.8-13.5 34-13.5 53.3s4.9 37.4 13.5 53.3zM208 256a48 48 0 1 1 96 0 48 48 0 1 1 -96 0z"></path></svg>
                  Submit Ticket
                </a>
                <a className="dropdown-item" href="/structure_cms/support_ticket/list">
                  <svg className="svg-inline--fa fa-comments" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="comments" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" data-fa-i2svg=""><g className="fa-duotone-group"><path className="fa-secondary" fill="currentColor" d="M231.5 383C348.9 372.9 448 288.3 448 176c0-5.2-.2-10.4-.6-15.5C555.1 167.1 640 243.2 640 336c0 38.6-14.7 74.3-39.6 103.4c3.5 9.4 8.7 17.7 14.2 24.7c4.8 6.2 9.7 11 13.3 14.3c1.8 1.6 3.3 2.9 4.3 3.7c.5 .4 .9 .7 1.1 .8l.2 .2 0 0 0 0c5.6 4.1 7.9 11.3 5.8 17.9c-2.1 6.6-8.3 11.1-15.2 11.1c-21.8 0-43.8-5.6-62.1-12.5c-9.2-3.5-17.8-7.4-25.2-11.4C505.9 503.3 470.2 512 432 512c-95.6 0-176.2-54.6-200.5-129z"></path><path className="fa-primary" fill="currentColor" d="M416 176c0 97.2-93.1 176-208 176c-38.2 0-73.9-8.7-104.7-23.9c-7.5 4-16 7.9-25.3 11.4C59.8 346.4 37.8 352 16 352c-6.9 0-13.1-4.5-15.2-11.1s.2-13.8 5.8-17.9l0 0 0 0 .2-.2c.2-.2 .6-.4 1.1-.8c1-.8 2.5-2 4.3-3.7c3.6-3.3 8.5-8.1 13.3-14.3c5.5-7 10.7-15.4 14.2-24.7C14.7 250.3 0 214.6 0 176C0 78.8 93.1 0 208 0S416 78.8 416 176z"></path></g></svg>
                  Support Center
                </a>
                <a className="dropdown-item" href="#">
                  <svg className="svg-inline--fa fa-building" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="building" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><g className="fa-duotone-group"><path className="fa-secondary" fill="currentColor" d="M0 48C0 21.5 21.5 0 48 0H336c26.5 0 48 21.5 48 48V464c0 26.5-21.5 48-48 48H240V432c0-26.5-21.5-48-48-48s-48 21.5-48 48v80H48c-26.5 0-48-21.5-48-48V48zM80 224c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H80zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zm112-16c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H272zM64 112v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16zM176 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H176zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H272c-8.8 0-16 7.2-16 16z"></path><path className="fa-primary" fill="currentColor" d="M80 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H80zm0 128c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H80zm96 0c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H176zm80 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16H272c-8.8 0-16 7.2-16 16zM160 112v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16zM272 96c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V112c0-8.8-7.2-16-16-16H272z"></path></g></svg>
                  Agency Center
                </a>
                <a className="dropdown-item" href="#">
                  <svg className="svg-inline--fa fa-user-circle" aria-hidden="true" focusable="false" data-prefix="fad" data-icon="user-circle" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><g className="fa-duotone-group"><path className="fa-secondary" fill="currentColor" d="M399 384.2C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8s107.8-24.7 143-63.8zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256zm256 16a72 72 0 1 0 0-144 72 72 0 1 0 0 144z"></path><path className="fa-primary" fill="currentColor" d="M256 272a72 72 0 1 0 0-144 72 72 0 1 0 0 144zm0 176c56.8 0 107.8-24.7 143-63.8C376.9 345.8 335.4 320 288 320H224c-47.4 0-88.9 25.8-111 64.2c35.2 39.2 86.2 63.8 143 63.8z"></path></g></svg>
                  My Account
                </a> */}
                <Link className="dropdown-item-m" to='/handleUserPasswordChange'>
                  <svg className="svg-inline--fa fa-key-skeleton" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="key-skeleton" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M304 64a80 80 0 1 1 0 160 80 80 0 1 1 0-160zm0 224c79.5 0 144-64.5 144-144S383.5 0 304 0S160 64.5 160 144c0 28.2 8.1 54.5 22.1 76.7L9.4 393.4c-12.5 12.5-12.5 32.8 0 45.3l64 64c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 416 96 397.3l41.4 41.4c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L141.3 352l86.1-86.1c22.2 14 48.5 22.1 76.7 22.1z"></path></svg>
                  &nbsp; Password Reset
                </Link>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item-m"  data-toggle="modal" data-target="#sign-out-modal" onClick={() => { signOutHandler(); }}>
                  <svg className="svg-inline--fa fa-power-off" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="power-off" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M288 32c0-17.7-14.3-32-32-32s-32 14.3-32 32V256c0 17.7 14.3 32 32 32s32-14.3 32-32V32zM143.5 120.6c13.6-11.3 15.4-31.5 4.1-45.1s-31.5-15.4-45.1-4.1C49.7 115.4 16 181.8 16 256c0 132.5 107.5 240 240 240s240-107.5 240-240c0-74.2-33.8-140.6-86.6-184.6c-13.6-11.3-33.8-9.4-45.1 4.1s-9.4 33.8 4.1 45.1c38.9 32.3 63.5 81 63.5 135.4c0 97.2-78.8 176-176 176s-176-78.8-176-176c0-54.4 24.7-103.1 63.5-135.4z"></path></svg>
                  &nbsp; Sign Out
                </a>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </nav>
    <div className={isModalShown ? "modal fade show" : 'modal fade'} id="sign-out-modal" data-backdrop="static" data-keyboard="false" tabIndex={-1} style={isModalShown ? {display: "block", paddingRight: "15px",backgroundColor: "rgba(0, 0, 0, 0.7)"} : {}} aria-labelledby="staticBackdropLabel" aria-hidden="true" role='dialog' >
    <div className="modal-dialog modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title" id="staticBackdropLabel">Sign Out</h5>
          <button type="button" onClick={() => {setIsModalShown(false)}} className="closek" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            <div className="col-sm col-4 text-center">
              <a onClick={() => {newSignOut()}} className="btnm btn-lightm btn-blockm">Sign out of<br/>this APP</a>
            </div>
            <div className="col-sm col-4 text-center">
              <a onClick={() => {onlyDeviceSignOut()}} className="btnm btn-lightm btn-blockm">Sign out of all<br/>apps on this device</a>
            </div>
            <div className="col-sm col-4 text-center">
              <a onClick={() => {newGlobalSignOut();}} id="auto-click-button" className={buttonClass} ><span>Sign out of all<br/>apps on all devices</span></a>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button type="button" onClick={()=>{setIsModalShown(false)}} className="btn btn-danger" data-dismiss="modal">Cancel</button>
        </div>
      </div>
    </div>
  </div>
    </>
  );
}

export default Header;
