import React, { ReactNode, createContext, useContext, useState } from 'react';
import './toast.css';
import Toast from './toast';

const ToastContext = createContext<any>(null);

type MyContextProviderProps = {
  children: ReactNode;
};

export const ToastContextProvider = ({ children }: MyContextProviderProps) => {
  const [toastState, setToastState] = useState({ message: '', status: '' });

  const showToast = (message: string, status: string) => {
    setToastState({ message, status });
  };

  const hideToast = () => {
    setToastState({ message: '', status: '' });
  };

  return (
    <ToastContext.Provider value={{ showToast, hideToast }}>
      <Toast message={toastState.message} status={toastState.status} onClose={hideToast} />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  const context = useContext(ToastContext);
  if (!context) {
    throw new Error('useToast must be used within a ToastProvider');
  }
  return context;
};
