import React, { useEffect, useState } from 'react';
import './toast.css';

interface Toast{
  message:String;
  onClose: ()=>void;
  status:String;
}

const Toast: React.FC<Toast> =({message, onClose, status})=>{
  const modalClassName = ` alert  position-absolute w-width text-center z-100 ${status=='success'  ? 'alert-success' : 'alert-danger'}`;

  return (<>
    {(status != '')&&(<div className="containera " >
      <div className='row'>
        <div className="col-sma"></div>
        <div className="col-sma-8 ">
          <div className={modalClassName} style={{fontSize: "18px",fontFamily: "arboria, sans-serif",fontWeight: "400",lineHeight: "1.5"}}>
           {message}
            <a aria-label="Close" className="close btnc btnc-info btnc-sm flash rounded-0" data-dismiss="alert" style={{backgroundColor: "#00000000"}} type="button">
              <span aria-hidden="true" className="flash-button" onClick={onClose}>Close</span>
            </a>
          </div>
        </div>
        <div className="col-sma"></div>
      </div>
    </div>)}
  </>);
}

export default Toast;
